import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mx-auto"},[_c(VCardText,[_c(VCardTitle,[_vm._t("title",[_vm._v(" Consumer Price Index "),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c(VIcon,[_vm._v("mdi-close")])],1)])],2),_c(VContainer,[_c('div',[_c('ul',[_c('li',[_vm._v(" The Consumer Price Index measures the average change in prices over time that consumers pay for a basket of goods and services. ")]),_c('li',[_vm._v("CPI is the most widely used measure of inflation.")]),_c('li',[_vm._v(" A CPI reading of 100 means that inflation is back to the level that it was in 1982-1984. ")])])]),_c('div',[_c('p',[_vm._v(" For example, readings of 175 and 275 would indicate a rise in the inflation level of 75% and 175% respectively. ")])]),(_vm.chart)?_c(VFadeTransition,[_c('cpi-chart')],1):_vm._e(),_c('div',{staticClass:"text-center"},[_c(VBtn,{staticClass:"text-capitalize my-2",attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.showCPIDetail()}}},[_vm._v("More about CPI")]),_c(VBtn,{staticClass:"text-capitalize my-2 ml-2",attrs:{"color":"info","outlined":""},on:{"click":function($event){_vm.chart = !_vm.chart}}},[_vm._v(" "+_vm._s(_vm.chart ? 'Hide' : 'Show')+" CPI History ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }